import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import './WorkoutOfTheDay.css';

interface Exercise {
  exercise_id: string;
  name: string;
  short_name: string;
  video_url: string;
  reps: number;
  per_side: boolean;
  set_type: string;
  exercise_number: number;
}

interface WodData {
  date: string;
  is_valid: boolean;
  main_exercises: Exercise[];
  challenge_exercises: Exercise[];
}

const WorkoutOfTheDay: React.FC = () => {
  const [wodData, setWodData] = useState<WodData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchWodData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/wods/today?date=${selectedDate}`);
        setWodData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching WOD data:', err);
        setError('Failed to load workout. Please try again later.');
        setLoading(false);
      }
    };

    fetchWodData();
  }, [selectedDate]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  if (loading) {
    return <div className="wod-container loading">Loading workout...</div>;
  }

  if (error) {
    return <div className="wod-container error">{error}</div>;
  }

  if (!wodData || !wodData.is_valid) {
    return <div className="wod-container no-workout">No workout scheduled for {selectedDate}.</div>;
  }

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="wod-container">
      <div className="date-selector">
        <input 
          type="date" 
          value={selectedDate} 
          onChange={handleDateChange}
          className="date-input"
        />
      </div>
      
      <h1 className="wod-title">Workout of the Day</h1>
      <h2 className="wod-date">{formatDate(wodData.date)}</h2>

      <section className="wod-section">
        <h3 className="section-title">Workout Exercises</h3>
        <div className="exercise-grid">
          {wodData.main_exercises.map((exercise) => (
            <div key={exercise.exercise_id} className="exercise-card">
              <div className="exercise-name">
                <span className="exercise-number">{exercise.exercise_number}.</span> {exercise.short_name || exercise.name}
              </div>
              <div className="exercise-reps">
                {exercise.reps} reps {exercise.per_side ? '(per side)' : ''}
              </div>
              <div className="exercise-video-container">
                <video 
                  src={exercise.video_url.replace('/upload/', '/upload/q_auto,w_640/')} 
                  autoPlay 
                  loop 
                  muted 
                  playsInline
                />
              </div>
            </div>
          ))}
        </div>
      </section>

      {wodData.challenge_exercises && wodData.challenge_exercises.length > 0 && (
        <section className="wod-section challenge-section">
          <h3 className="section-title">Challenge Exercises</h3>
          <div className="exercise-grid">
            {wodData.challenge_exercises.map((exercise) => (
              <div key={exercise.exercise_id} className="exercise-card">
                <div className="exercise-name">
                  {exercise.short_name || exercise.name}
                </div>
                <div className="exercise-reps">
                  {exercise.reps} reps {exercise.per_side ? '(per side)' : ''}
                </div>
                <div className="exercise-video-container">
                  <video 
                    src={exercise.video_url.replace('/upload/', '/upload/q_auto,w_640/')} 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default WorkoutOfTheDay; 