import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTimes, FaBars } from 'react-icons/fa';



const NavMenu: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isWaitlistPage = location.pathname === '/waitlist';
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY <= 60) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div>
      {showNavbar && (
        <nav className={`navbar ${isHomePage ? 'home' : ''}`} style={style}>
          <div className="navbar-left">
            <Link to="/" className="menu-item">
              <img src="/logo-text.svg" alt="Logo" className={`navbar-logo logo-text ${isHomePage ? 'inverted' : ''}`} />
              <div className={`logo-box ${isHomePage ? 'inverted' : ''}`}><img src="/logo-icon.svg" alt="Logo Icon" className="navbar-logo logo-icon" /></div>
            </Link>
            <div className="navbar-left-links">
              <Link to="/about" className="menu-item">About</Link>
              <Link to="/memberships" className="menu-item">Memberships</Link>
              <a href="https://backoffice.bsport.io" className="menu-item" target="_blank" rel="noopener noreferrer">Login</a>
            </div>
          </div>
          <div className="navbar-right">
            <Link to="/become-a-trainer" className="menu-item">Become a Trainer</Link>
            
            <Link to="/workouts" className="menu-item">BOOK WORKOUT</Link>
            <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </div>
          </div>
        </nav>
      )}
      <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
        <div className="close-icon" onClick={() => setMenuOpen(false)}><FaTimes size={24} /></div>
        <Link to="/about" className="menu-item" onClick={() => setMenuOpen(false)}>About</Link>
        <Link to="/memberships" className="menu-item" onClick={() => setMenuOpen(false)}>Memberships</Link>
        <Link to="/become-a-trainer" className="menu-item" onClick={() => setMenuOpen(false)}>Become a Trainer</Link>
        <a href="https://backoffice.bsport.io" className="menu-item" onClick={() => setMenuOpen(false)} target="_blank" rel="noopener noreferrer">Login</a>
        <Link to="/workouts" className="menu-item" onClick={() => setMenuOpen(false)}>BOOK WORKOUT</Link>
      </div>
    </div>
  );
};

export default NavMenu;